import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchAllPersonnel(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('pamis/admin/personnel/Personnel')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    createPersonnel(ctx, personnelData) {
      return new Promise((resolve, reject) => {
        axios
          .post('pamis/admin/personnel/Personnel/Create', personnelData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    updatePersonnel(ctx, personnelData) {
      return new Promise((resolve, reject) => {
        axios
          .put('pamis/admin/personnel/Personnel/Update', personnelData, { params: { id: personnelData.id } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    deletePersonnel(ctx, personnelData) {
      return new Promise((resolve, reject) => {
        axios
          .delete('pamis/admin/personnel/Personnel/Delete', { params: { id: personnelData.id } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

  },
}
