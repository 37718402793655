<template>
    <div>
      <personnel-add-new :is-add-new-personnel-sidebar-active.sync="isAddNewPersonnelSidebarActive"
                                      @refetch-data="refetchData"
                                      :actionType="actionType"
                                      ref="personnelAddNewComponent" />
  
      <!-- Table Container Card -->
      <b-card no-body
              class="mb-0">
        <div class="m-2">
          <!-- Table Top -->
          <b-row>
            <!-- Per Page -->
            <b-col cols="12"
                   md="6"
                   class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
              <label>Show</label>
              <v-select v-model="perPage"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="perPageOptions"
                        :clearable="false"
                        class="per-page-selector d-inline-block mx-50" />
              <label>entries</label>
            </b-col>
  
            <!-- Search -->
            <b-col cols="12"
                   md="6">
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input v-model="search"
                              class="d-inline-block mr-1"
                              placeholder="Search..." />
                <b-button variant="primary"
                          @click="newPersonnel()">
                  <span class="text-nowrap">Add Personnel</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>
  
        <b-table ref="refPersonnelListTable"
                 class="position-relative"
                 :items="tableItems"
                 responsive
                 :fields="tableColumns"
                 primary-key="id"
                 sort-by="id"
                 :filter="search"
                 show-empty
                 empty-text="No matching records found"
                 :per-page="perPage"
                 :sort-desc.sync="isSortDirDesc"
                 :current-page="currentPage">
  
          <template #cell(actions)="data">
            <b-dropdown
                        variant="link"
                        toggle-class="text-decoration-none"
                        no-caret>
              <template v-slot:button-content>
                <feather-icon
                              icon="MoreVerticalIcon"
                              size="16"
                              class="text-body align-middle mr-25" />
              </template>
              <b-dropdown-item @click="changePersonnel(data.item, 1)">
                <feather-icon
                              icon="EditIcon"
                              class="mr-50" />
                <span>{{ $t("Edit") }}</span>
              </b-dropdown-item>
              <b-dropdown-item @click="changePersonnel(data.item, 2)">
                <feather-icon
                              icon="TrashIcon"
                              class="mr-50" />
                <span>{{ $t("Delete") }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
  
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col cols="12"
                   sm="6"
                   class="d-flex align-items-center justify-content-center justify-content-sm-start">
              <!-- <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span> -->
              <span class="text-muted">Showing {{ this.perPage * (this.currentPage - 1) + 1 }} to {{ this.perPage *
                (this.currentPage) }} of {{ totalItem }} entries</span>
            </b-col>
            <!-- Pagination -->
            <b-col cols="12"
                   sm="6"
                   class="d-flex align-items-center justify-content-center justify-content-sm-end">
              <b-pagination v-model="currentPage"
                            :total-rows="totalItem"
                            :per-page="perPage"
                            first-number
                            last-number
                            class="mb-0 mt-1 mt-sm-0"
                            prev-class="prev-item"
                            next-class="next-item">
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon"
                                size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon"
                                size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </div>
  </template>
  
  <script>
  import {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
  } from "bootstrap-vue";
  import vSelect from "vue-select";
  import store from "@/store";
  import { ref, onUnmounted } from "@vue/composition-api";
  import usePersonnelList from "./usePersonnelList";
  import personnelStoreModule from "../personnelStoreModule";
  import PersonnelAddNew from "./PersonnelAddNew.vue";
  
  export default {
    computed: {
    },
    filters: {},
    methods: {
      startMigrating() {
        this.migrationStarted = !this.migrationStarted;
      },
      changePersonnel(e, actionType) {
        this.selectedPersonnel = JSON.parse(JSON.stringify(e));
        this.isAddNewPersonnelSidebarActive = true;
        this.actionType = actionType;
      },
      newPersonnel() {
        this.selectedPersonnel = {
          id: null,
          name: null,
          surname: null,
          registryNumber: null
        };
        this.isAddNewPersonnelSidebarActive = true;
        this.actionType = 0;
      },
      refreshTable: function () {
        refetchData();
      },
    },
    provide() {
      const personneldata = {};
  
      Object.defineProperty(personneldata, "selectedPersonnel", {
        enumerable: true,
        get: () => this.selectedPersonnel,
      });
  
      return {
        personneldata,
      };
    },
    components: {
      PersonnelAddNew,
  
      BCard,
      BRow,
      BCol,
      BFormInput,
      BButton,
      BTable,
      BMedia,
      BAvatar,
      BLink,
      BBadge,
      BDropdown,
      BDropdownItem,
      BPagination,
  
      vSelect,
    },
    data() {
      return {
        migrationStarted: false,
        selectedPersonnel: null,
        perPage: 10,
        currentPage: 1,
        perPageOptions: [10, 25, 50, 100],
        search: '',
        actionType: 0
      };
    },
    setup() {
      const MOULD_APP_STORE_MODULE_NAME = "pamis-personnel";
  
      // Register module
      if (!store.hasModule(MOULD_APP_STORE_MODULE_NAME))
        store.registerModule(MOULD_APP_STORE_MODULE_NAME, personnelStoreModule);
  
      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(MOULD_APP_STORE_MODULE_NAME))
          store.unregisterModule(MOULD_APP_STORE_MODULE_NAME);
      });
  
      const isAddNewPersonnelSidebarActive = ref(false);
  
      const {
        refetchData,
        tableColumns,
        totalItem,
        tableItems,
        isSortDirDesc,
        refPersonnelListTable
      } = usePersonnelList();
      return {
        refetchData,
        isAddNewPersonnelSidebarActive,
        tableColumns,
        totalItem,
        isSortDirDesc,
        tableItems,
        refPersonnelListTable
      };
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
  </style>
  
  <style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  </style>
  