<template>
    <b-sidebar id="add-new-personnel-sidebar"
               :visible="isAddNewPersonnelSidebarActive"
               bg-variant="white"
               sidebar-class="sidebar-lg"
               shadow
               backdrop
               no-header
               right
               @hidden="resetForm"
               @change="(val) => $emit('update:is-add-new-personnel-sidebar-active', val)">
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            {{ actionType == 0 ? " Add New Personnel" : actionType == 1 ? " Update Personnel" :
              "Delete Personnel" }}
          </h5>
  
          <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
  
        </div>
  
        <!-- BODY -->
        <validation-observer #default="{ handleSubmit }">
          <!-- Form -->
          <b-form class="p-2" @submit.prevent="handleSubmit(submitForm)" @reset.prevent="resetForm">
  
            <validation-provider #default="validationContext"
                                 name="Personnel Name"
                                 rules="required">
              <b-form-group label="Name"
                            label-for="personnel-name">
                <b-form-input id="personnel-name"
                              v-model="currentPersonnel.name" autofocus
                              :state="getValidationState(validationContext)"
                              trim
                              :disabled="actionType == 2"
                              placeholder="Personnel Name" />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider #default="validationContext"
                                 name="Personnel Surname"
                                 rules="required">
              <b-form-group label="Surname"
                            label-for="personnel-surname">
                <b-form-input id="personnel-surname"
                              v-model="currentPersonnel.surname" autofocus
                              :state="getValidationState(validationContext)"
                              trim
                              :disabled="actionType == 2"
                              placeholder="Personnel Surname" />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
  
            <b-form-group label="Registry Number"
                          label-for="personnel-registryNumber">
              <b-form-input id="personnel-registryNumber"
                            v-model="currentPersonnel.registryNumber" autofocus
                            trim
                            :disabled="actionType == 2"
                            placeholder="Registry Number" />
            </b-form-group>
  
  
            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" type="submit"
                        v-if="currentPersonnel == null || currentPersonnel.id == null">
                Add
              </b-button>
  
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2"
                        @click="updatePersonnel"
                        v-if="currentPersonnel != null && currentPersonnel.id != null && actionType == 1">
                Update
              </b-button>
  
              <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" class="mr-2"
                        v-if="currentPersonnel != null && currentPersonnel.id != null && actionType == 2"
                        variant="outline-danger"
                        @click="deletePersonnel">
                Delete
              </b-button>
  
              <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" variant="outline-secondary" @click="hide">
                Cancel
              </b-button>
            </div>
  
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </template>
  
  <script>
  import {
    BSidebar, BForm, BFormGroup, BFormInput, BFormCheckbox, BFormInvalidFeedback, BButton, BCardText
  } from 'bootstrap-vue'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { ref } from '@vue/composition-api'
  import { required } from '@validations'
  import formValidation from '@core/comp-functions/forms/form-validation'
  import Ripple from 'vue-ripple-directive'
  import vSelect from 'vue-select'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  
  
  export default {
    components: {
      BSidebar,
      BForm,
      BFormGroup,
      BFormInput,
      BFormCheckbox,
      BFormInvalidFeedback,
      BButton,
      vSelect,
      BCardText,
      ValidationProvider,
      ValidationObserver
    },
    directives: {
      Ripple,
    },
    model: {
      prop: 'isAddNewPersonnelSidebarActive',
      event: 'update:is-add-new-personnel-sidebar-active',
    },
    props: {
      isAddNewPersonnelSidebarActive: {
        type: Boolean,
        required: true,
      },
      actionType: {
        type: Number,
        required: true,
      },
    },
    methods: {
      deletePersonnel() {
        this.$store.dispatch('pamis-personnel/deletePersonnel', this.currentPersonnel)
          .then((result) => {
            this.$emit('refetch-data')
            this.$emit('update:is-add-new-personnel-sidebar-active', false)
            this.notification()
          })
          .catch((error) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Delete Personnel Error',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          });
      },
      updatePersonnel() {
        this.$store.dispatch('pamis-personnel/updatePersonnel', this.currentPersonnel)
          .then((result) => {
            this.$emit('refetch-data')
            this.$emit('update:is-add-new-personnel-sidebar-active', false)
            // this.$emit('show-success-toast')
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Personnel Successfully Updated',
                icon: 'BellIcon',
              },
            })
          }).catch(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Update Personnel Error',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          });
  
      },
      submitForm() {
          this.$store.dispatch('pamis-personnel/createPersonnel', this.currentPersonnel)
            .then((result) => {
              this.$emit('refetch-data')
              this.$emit('update:is-add-new-personnel-sidebar-active', false)
            })
            .catch(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Create Personnel Error',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            });
      },
      notification() {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Process Successfully',
            icon: 'BellIcon',
          },
        })
      },
    },
  
    watch: {
      personneldata: {
        handler: function (n, o) {
          if (n == null || n == undefined) {
            this.currentPersonnel = {
              id: null,
              name: null,
              surname: null,
              registryNumber: null
            }
          } else {
            this.currentPersonnel = n.selectedPersonnel;
          }
        },
        deep: true
      }
  
    },
    data() {
      return {
        required,
        currentPersonnel: null
      }
    },
    inject: ['personneldata'],
    setup(props, { emit }) {
      const blankPersonnelData = {
        id: null,
        name: null,
        surname: null,
        registryNumber: null
      }
      const productionResultTypeData = ref(JSON.parse(JSON.stringify(blankPersonnelData)))
      const {
        getValidationState,
      } = formValidation(productionResultTypeData)
  
      return {
        productionResultTypeData,
        getValidationState,
      }
    },
  }
  </script>
  
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  
  #add-new-personnel-sidebar {
    .vs__dropdown-menu {
      max-height: 200px !important;
    }
  }
  </style>
  